import { User } from '../SYS/user';

export class Laboratory {
    id: number;
    patientId: number;
    blood_glucose: number;
    creatina: number;
    uric_nitrogen: number;
    albumin: number;
    pre_albumin: number;
    plasma_protein: number;
    uric_acid_value: number;
    glycosylated_hemoglobin: number;
    hemoglobin: number;
    Red_blood_cells: number;
    hematocrit: number;
    white_blood_cells: number;
    diagnosisId: number;
    doctorId: number;
    doctor: User;
    trigliceridos: number;
    colesterol: number;
    create_date: Date;
    removed: Boolean
    constructor(laboratory: any) {
        this.id = laboratory.id;
        this.patientId = laboratory.patientId;
        this.blood_glucose = laboratory.blood_glucose;
        this.creatina = laboratory.creatina;
        this.uric_nitrogen = laboratory.uric_nitrogen;
        this.albumin = laboratory.albumin;
        this.pre_albumin = laboratory.pre_albumin;
        this.plasma_protein = laboratory.plasma_protein;
        this.uric_acid_value = laboratory.uric_acid_value;
        this.glycosylated_hemoglobin = laboratory.glycosylated_hemoglobin;
        this.hemoglobin = laboratory.hemoglobin;
        this.Red_blood_cells = laboratory.Red_blood_cells;
        this.hematocrit = laboratory.hematocrit;
        this.white_blood_cells = laboratory.white_blood_cells;
        this.diagnosisId = laboratory.diagnosisId;
        this.trigliceridos = laboratory.trigliceridos;
        this.colesterol = laboratory.colesterol;
        this.doctorId = laboratory.doctorId
        this.doctor = laboratory.doctor ? new User(laboratory.doctor) : new User({})
        this.create_date = laboratory.create_date
        this.removed = laboratory.removed
    }
}
