import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FoodService {

  private actionUrl: string;

  constructor(private http: HttpClient) {
    this.actionUrl = environment.apiUrl;
  }
  public async getAllFoodsPaginator(paginator: any) {
    let data: any = await new Promise<any>(resolve =>
      this.http.get<any>(this.actionUrl
        + 'foods-list/' + paginator.newFilter.companyId
        + "/currentPage/" + paginator.config.currentPage
        + "/itemsPerPage/" + paginator.config.itemsPerPage
        + "/search/" + paginator.newFilter.search
        + "/column/" + paginator.column
        + "/direction/" + paginator.direction)
        .subscribe(
          (res: any) => {
            // console.log('HTTP response', res);
            if (res.hasErr) {
              resolve([])
            } else {
              resolve(res)
            }
          },
          err => console.log('HTTP Error', err),
          () => console.log('HTTP request completed.')
        ))
    return data
  }
  public async getConceptTypeOrGroup(companyId: number, type: string, search: string) {
    let data: any = await new Promise<any>((resolve, reject) =>
      this.http.get<any>(this.actionUrl + 'foods-filter/' + companyId +
        '/type/' + type +
        '/search/' + search)
        .subscribe(
          (res: any) => {
            // console.log('HTTP response', res);
            if (res.hasErr) {
              reject('fail for some reason')
            } else {
              resolve(res)
            }
          },
          err => console.log('HTTP Error', err),
          () => console.log('HTTP request completed.')
        ))
    return data
  }
  public async getFoodTypeOrGroupList(companyId: number, type: string) {
    let data: any = await new Promise<any>((resolve, reject) =>
      this.http.get<any>(this.actionUrl + 'foods-types/' + companyId +
        '/type/' + type)
        .subscribe(
          (res: any) => {
            // console.log('HTTP response', res);
            if (res.hasErr) {
              reject('fail for some reason')
            } else {
              resolve(res)
            }
          },
          err => console.log('HTTP Error', err),
          () => console.log('HTTP request completed.')
        ))
    return data
  }
  public async SaveFoodRecord(model: any) {
    let data: any = await new Promise<any>(resolve =>
      this.http.post<any>(this.actionUrl + 'food/save', model)
        .subscribe(
          (res: any) => {
            //  console.log('HTTP response', res);
            if (res.hasErr) {
              resolve([])
            } else {
              resolve(res)
            }
          },
          err => console.log('HTTP Error', err),
          () => console.log('HTTP request completed.')
        ))
    return data
  }
  public async GetFoodRecord(id: any) {
    let data: any = await new Promise<any>((resolve, reject) =>
      this.http.get<any>(this.actionUrl + 'food/' + id)
        .subscribe(
          (res: any) => {
            // console.log('HTTP response', res);
            if (res.hasErr) {
              reject('fail for some reason')
            } else {
              resolve(res)
            }
          },
          err => console.log('HTTP Error', err),
          () => console.log('HTTP request completed.')
        ))
    return data
  }
  /* 
  public getAll<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl);
  }

  public getSingle<T>(id: number): Observable<T> {
    return this.http.get<T>(this.actionUrl + id);
  }

  public add<T>(itemName: string): Observable<T> {
    const toAdd = JSON.stringify({ ItemName: itemName });

    return this.http.post<T>(this.actionUrl, toAdd);
  }

  public update<T>(id: number, itemToUpdate: any): Observable<T> {
    return this.http
      .put<T>(this.actionUrl + id, JSON.stringify(itemToUpdate));
  }

  public delete<T>(id: number): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id);
  } */
}
