export class NutritionalRequirement {
    id: number;
    age: number;
    genderId: number;
    calcium: number;
    phosphorus: number;
    iron: number;
    sodium: number;
    potassium: number;
    zinc: number;
    copper: number;
    magnesium: number;
    iodo: number;
    selenium: number;
    retinol: number;
    thiamine: number;
    riboflavin: number;
    niacin: number;
    pyridoxine: number;
    acido_folic: number;
    cobalamin: number;
    acido_ascorbic: number;
    total_calciferol_d: number;
    phyloquinone: number;
    tocopherol: number;
    kcal: number;
    protein: number;
    ch: number;
    fat: number;
    fiber: number;
    constructor(nutritionalRequirement:any){
        this.id=nutritionalRequirement.id;
        this.age=nutritionalRequirement.age;
        this.genderId=nutritionalRequirement.genderId;
        this.calcium=nutritionalRequirement.calcium;
        this.phosphorus=nutritionalRequirement.phosphorus;
        this.iron=nutritionalRequirement.iron;
        this.sodium=nutritionalRequirement.sodium;
        this.potassium=nutritionalRequirement.potassium;
        this.zinc=nutritionalRequirement.zinc;
        this.copper=nutritionalRequirement.copper;
        this.magnesium=nutritionalRequirement.magnesium;
        this.iodo=nutritionalRequirement.iodo;
        this.selenium=nutritionalRequirement.selenium;
        this.retinol=nutritionalRequirement.retinol;
        this.thiamine=nutritionalRequirement.thiamine;
        this.riboflavin=nutritionalRequirement.riboflavin;
        this.niacin=nutritionalRequirement.niacin;
        this.pyridoxine=nutritionalRequirement.pyridoxine;
        this.acido_folic=nutritionalRequirement.acido_folic;
        this.cobalamin=nutritionalRequirement.cobalamin;
        this.acido_ascorbic=nutritionalRequirement.acido_ascorbic;
        this.total_calciferol_d=nutritionalRequirement.total_calciferol_d;
        this.phyloquinone=nutritionalRequirement.phyloquinone;
        this.tocopherol=nutritionalRequirement.tocopherol;
    }
};
