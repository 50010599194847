import { Company } from './company';

export class Foods {
    id: number;
    companyId: number;
    type:string;
    name:string;
    group:string;
    h2o:number;
    kcal:number;
    protein:number;
    ch:number;
    total_fat:number;
    ags:number;
    agm:number;
    agp:number;
    ag_trans:number;
    total_cholesterol:number;
    ag_linolenico_omega_3:number;
    ag_linoleico_omega_6:number;
    total_fibers:number;
    soluble_fibers:number;
    insoluble_fibers:number;
    calcium:number;
    phosphorus:number;
    iron:number;
    sodium:number;
    potassium:number;
    zinc:number;
    copper:number;
    magnesium:number;
    iodo:number;
    chlorine:number;
    fluor:number;
    manganese:number;
    selenium:number;
    retinol:number;
    thiamine:number;
    riboflavin:number;
    niacin:number;
    acido_pantothenic:number;
    pyridoxine:number;
    biotin:number;
    choline:number;
    acido_folic:number;
    cobalamin:number;
    acido_ascorbic:number;
    total_calciferol_d:number;
    ergocalciferol_d2:number;
    colecalciferol_d3:number;
    tocopherol:number;
    phyloquinone:number;
    menaquinona_k2:number;
    menadiona_k3:number;
    waste:number;
    total_ash:number;
    
   
    constructor(food: any) {
        this.id = food.id;
        this.companyId = food.companyId;
        this.type=food.type;
        this.name=food.name;
        this.group=food.group;
        this.h2o=food.h2o;
        this.kcal=food.kcal;
        this.protein=food.protein;
        this.ch=food.ch;
        this.total_fat=food.total_fat;
        this.ags=food.ags;
        this.agm=food.agm;
        this.agp=food.agp;
        this.ag_trans=food.ag_trans;
        this.total_cholesterol=food.total_cholesterol;
        this.ag_linolenico_omega_3=food.ag_linolenico_omega_3;
        this.ag_linoleico_omega_6=food.ag_linoleico_omega_6;
        this.total_fibers=food.total_fibers;
        this.soluble_fibers=food.soluble_fibers;
        this.insoluble_fibers=food.insoluble_fibers;
        this.calcium=food.calcium;
        this.phosphorus=food.phosphorus;
        this.iron=food.iron;
        this.sodium=food.sodium;
        this.potassium=food.potassium;
        this.zinc=food.zinc;
        this.copper=food.copper;
        this.magnesium=food.magnesium;
        this.iodo=food.iodo;
        this.chlorine=food.chlorine;
        this.fluor=food.fluor;
        this.manganese=food.manganese;
        this.selenium=food.selenium;
        this.retinol=food.retinol;
        this.thiamine=food.thiamine;
        this.riboflavin=food.riboflavin;
        this.niacin=food.niacin;
        this.acido_pantothenic=food.acido_pantothenic;
        this.pyridoxine=food.pyridoxine;
        this.biotin=food.biotin;
        this.choline=food.choline;
        this.acido_folic=food.acido_folic;
        this.cobalamin=food.cobalamin;
        this.acido_ascorbic=food.acido_ascorbic;
        this.total_calciferol_d=food.total_calciferol_d;
        this.ergocalciferol_d2=food.ergocalciferol_d2;
        this.colecalciferol_d3=food.colecalciferol_d3;
        this.tocopherol=food.tocopherol;
        this.phyloquinone=food.phyloquinone;
        this.menaquinona_k2=food.menaquinona_k2;
        this.menadiona_k3=food.menadiona_k3;
        this.waste=food.waste;
        this.total_ash=food.total_ash;
        
    }
};
