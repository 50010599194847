import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private actionUrl: string;
  constructor(private http: HttpClient) {
    this.actionUrl = environment.apiUrl;
  }

  public getAllCompaniesPaginator<T>(paginator: any): Observable<T> {
    return this.http.get<T>(this.actionUrl
      + 'companies-list/' + paginator.newFilter.companyId
      + "/currentPage/" + paginator.config.currentPage
      + "/itemsPerPage/" + paginator.config.itemsPerPage
      + "/search/" + paginator.newFilter.search
      + "/column/" + paginator.column
      + "/direction/" + paginator.direction);
  }

  public getSingleCompany<T>(id: number): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'company/' + id);
  }
  /* start get list of all companies */
  private AllCompanies<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'companies');
  }
  public async getCompany() {
    let data: any = await new Promise<any>(resolve => this.AllCompanies()
    .pipe(
      catchError(err => {
        console.log('caught rethrown error, providing fallback value');
        return of([]);
      })
  )
    .subscribe(
      (res: any) => {
        //console.log('HTTP response', res); 
        if(res.hasErr){
          resolve([])
        }else{
          resolve(res)
        }
       
      },
      err => console.log('HTTP Error', err),
      () => console.log('HTTP request completed.')
    ))
    return data
  }
  /* end get list of all companies */
  public addCompany<T>(company: any): Observable<T> {
    const toAdd = JSON.stringify(company);

    return this.http.post<T>(this.actionUrl, toAdd);
  }

  public update(id: number, itemToUpdate: any, image: any) {
    itemToUpdate.image = image
    return this.http
      .put(this.actionUrl + 'company/' + id, itemToUpdate);
  }

  public delete<T>(id: number): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id);
  }




}
